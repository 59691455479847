import React, { useEffect, useState } from "react";
import "./App.css";
import dayjs from "dayjs";
import { RetellWebClient } from "retell-client-js-sdk";
import {
  PhoneIcon,
} from "@heroicons/react/24/solid";
import NoSleep from "nosleep.js";
import Vapi from "@vapi-ai/web";

const defaultAgentId = "5d0b84613fa3fa6e0cdd45839b042a18";

interface RegisterCallResponse {
  callId?: string;
  sampleRate: number;
}

const webClient = new RetellWebClient();

const vapi = new Vapi("e7e9d5e4-02d4-418a-b793-d90555bff2ce");

const App = () => {
  const [isCalling, setIsCalling] = useState(false);
  const [pressedButton, setPressedButton] = useState(false);
  const [shownScheduling, setShownScheduling] = useState(false);

  // Initialize the SDK
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const useVapi = searchParams.get("v");
    if (useVapi === "true") {
      vapi.on("call-start", () => {
        setIsCalling(true);
        setPressedButton(false);
      });
      vapi.on("call-end", () => {
        setIsCalling(false);
      });
      vapi.on("error", (error) => {
        console.error("An error occurred:", error);
        setIsCalling(false);
      });
    } else {
      // Setup event listeners
      /*webClient.on("call_started", () => {
        console.log("conversationStarted");
        });*/

      webClient.on("call_ended", () => {
        setIsCalling(false); // Update button to "Start" when conversation ends
      });

      webClient.on("error", (error) => {
        console.error("An error occurred:", error);
        setIsCalling(false); // Update button to "Start" in case of error
      });

      /*webClient.on("update", (update) => {
        // Print live transcript as needed
        console.log("update", update);
        });*/
      navigator.mediaDevices.getUserMedia({ audio: true }).catch(console.error);
    }

    if (searchParams.get("sleep") === "true") {
      document.addEventListener("click", function enableNoSleep() {
        const noSleep = new NoSleep();
        document.removeEventListener("click", enableNoSleep, false);
        noSleep.enable();
      }, false);
    }
  }, []);

  const toggleConversation = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const useVapi = searchParams.get("v");
    let agentId = searchParams.get("agent_id");
    if (!agentId) {
      agentId = defaultAgentId;
    }
    if (useVapi === "true") {
      if (isCalling) {
        vapi.stop();
        if (!shownScheduling) {
          window.open("https://cal.com/havana/25min", "_blank");
          setShownScheduling(true);
        }
      } else {
        setPressedButton(true);
        vapi.start(agentId);
      }
    } else {
      if (isCalling) {
        webClient.stopCall();
        if (!shownScheduling) {
          window.open("https://cal.com/havana/25min", "_blank");
          setShownScheduling(true);
        }
      } else {
        setPressedButton(true);
        const registerCallResponse = await registerCall(agentId);
        if (registerCallResponse.call_id) {
          webClient
            .startCall({
              //callId: registerCallResponse.call_id,
              accessToken: registerCallResponse.access_token,
              //sampleRate: registerCallResponse.sample_rate,
              //enableUpdate: true,
            })
            .catch(console.error);
          setIsCalling(true); // Update button to "Stop" when conversation starts
          setPressedButton(false);
        }
      }
    }
  };

  async function registerCall(agentId: string): Promise<RegisterCallResponse> {
    let dateHelper = `Today (${dayjs().format("dddd")}): ${dayjs().format(
      "MMM D, YYYY",
    )}\n`;
    dateHelper += `Tomorrow (${dayjs()
      .add(1, "day")
      .format("dddd")}): ${dayjs().add(1, "day").format("MMM D, YYYY")}\n`;
    let checkedDay = dayjs().add(1, "day");
    let sundays = 0;
    for (;;) {
      checkedDay = checkedDay.add(1, "day");
      if (sundays === 0) {
        dateHelper += `${checkedDay.format("dddd")}: ${checkedDay.format(
          "MMM D, YYYY",
        )}\n`;
      } else if (sundays === 1) {
        dateHelper += `Next ${checkedDay.format("dddd")}: ${checkedDay.format(
          "MMM D, YYYY",
        )}\n`;
      } else if (sundays === 2) {
        break;
      }
      if (checkedDay.day() === 0) {
        sundays++;
      }
    }
    dateHelper += `Time: ${dayjs().format("h:mm A")}\n`;

    const times = [
      dayjs().add(1, "day").set("hour", 9).set("minute", 30).set("second", 0).set("millisecond", 0),
      dayjs().add(1, "day").set("hour", 15).set("minute", 30).set("second", 0).set("millisecond", 0),
      dayjs().add(3, "day").set("hour", 9).set("minute", 30).set("second", 0).set("millisecond", 0),
    ];
    const timesForDay = new Map<string, dayjs.Dayjs[]>();
    for (const time of times) {
      const t = dayjs(time);
      const dateKey = t.format("YYYY-MM-DD");
      if (!timesForDay.has(dateKey)) {
        timesForDay.set(dateKey, []);
      }
      timesForDay.get(dateKey)?.push(t);
    }
    const sortedTimes = Array.from(timesForDay.entries()).sort((a, b) =>
      a[0].localeCompare(b[0]),
    );
    let firstTime = true;
    let appointmentHelper = "";
    for (const [, times] of sortedTimes) {
      // e.g.
      // * 9:30 AM or 5 PM on Monday (Jun 23)
      // * Or 9:30 AM or 5 PM on Tuesday (Jun 24)
      appointmentHelper += `* ${firstTime ? "" : "Or "}${times
        .map((t) => t.format("h:mm A"))
        .join(" or ")} on ${times[0].format("dddd")} (${times[0].format(
        "MMM D",
      )})\n`;
      firstTime = false;
    }
    if (times.length === 0) {
      appointmentHelper = "No appointment slots available.";
    }


    try {
      // Replace with your server url
      const response = await fetch(
        "https://vkvmmozzqxi4636ga4zdo46gzy0mybqa.lambda-url.eu-west-1.on.aws/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            agent_id: agentId,
            variables: {
              name: "Nathan",
              token: "testtoken123",
              date_helper: dateHelper,
              appointment_helper: appointmentHelper,
              time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
          }),
        },
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data: RegisterCallResponse = await response.json();
      return data;
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  }

  return (
    <div>
      <header>
        <a href="https://www.tryhavana.com">
          <img src="/havana_logo_words.png" className="w-64" alt="Havana Logo" />
        </a>
      </header>
      <div className="flex pt-24 p-4 text-center w-full flex-col items-center space-y-14">
        <div className="text-2xl max-w-lg">
          Receive a call from our AI enrollment advisor. Find a quiet place for the best experience.
        </div>
        <div>
          <button onClick={toggleConversation}>
              {isCalling ? (
                <div className="rounded-full border bg-red-300 p-4">
                  <PhoneIcon className="h-10 w-10 text-white rotate-[135deg]" />
                </div>
              ) : pressedButton ? (
                <div className="rounded-full border bg-green-500 p-4">
                  <Spinner />
                </div>
              ) : (
                <div className="rounded-full border bg-green-500 p-4 animate-pulse">
                  <PhoneIcon className="h-10 w-10 text-white" />
                </div>
              )}
          </button>
        </div>
        <div>
          Our AI can also help to answer calls when your advisors are occupied. Interested to learn more? Let's find a time <a href="https://cal.com/havana/25min" className="underline text-blue-500">here</a>.
        </div>
      </div>
    </div>
  );
};


const Spinner = () => {
  return (
    <svg className="Spinner-svg" viewBox="0 0 24 24">
      <circle
        className="Spinner-circle"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="Spinner-path"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};

export default App;
